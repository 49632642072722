import React, { useState, useEffect } from 'react'
import c1 from '../../Images/Hotels/paris.jpeg'
import c2 from '../../Images/Hotels/dubai.jpeg'
import c3 from '../../Images/Hotels/losangles.jpg'
import c4 from '../../Images/Hotels/miami.jpg'
import img1 from '../../Images/Packages/umrah-package.jpg'
import img2 from '../../Images/Packages/AlAqsa-package.jpg'
import london from '../../Images/Hotels/London.jpg'
import birmingham from '../../Images/Hotels/birmingham.jpg'
import madinah from '../../Images/Carousal/1.jpg'
import makkah from '../../Images/Carousal/2.jpg'
import jeddah from '../../Images/Carousal/jeddah.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PopularDestinatiosn from '../../Pages/Home/PopularDestinations'
import InternationalRoutes from '../InternationRoutes/InternationalRoutes'
import {
  faHeadset,
  faPersonWalkingLuggage,
  faPlaneArrival,
  faPlaneDeparture,
  faSackDollar,
  faUmbrellaBeach,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import CIcon from '@coreui/icons-react'
import { cilMoon } from '@coreui/icons'
import Loading from '../Loading/Loader'
import { useDispatch } from "react-redux";
import { useTransition } from 'react'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { ViewTourDetail,fetchHotelsSearh,fetchHotels  } from '../../Redux/Actions/actions'
import {
  Hotelapitoken,
  CustomerDomainName,
  ApiEndPoint
} from '../GlobalData/GlobalData'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Axios from 'axios'
import { useTranslation } from 'react-i18next'
function Packages () {
  const {t,i18n}=useTranslation();
  const language=i18n.language;
  const [isloading, setLoading] = useState(false)
  const [packagescard, setPackagescard] = useState([])
  const [allcateogries, setAllCateogries] = useState([])
  const [allTours, setAllTours] = useState([])
  const [showTours, setShowTours] = useState([])
  const [itemsToShow, setItemsToShow] = useState(4)
  var url = CustomerDomainName()
  const Dispatch = useDispatch()
  const navigation = useNavigate()
  const [activeItem, setActiveItem] = useState(0) // Initialize activeItem with the default value (0).
  var apiendpoint = ApiEndPoint()
  const handleCategorySelect = index => {
    setActiveItem(index)
    var filter = allTours.filter(
      tour => Number(tour.categories) === allcateogries[index].id
    )
    setShowTours(filter)
    // Set the selected category as the active one.
  }
  useEffect(() => {
    GetPackages()
  }, [])

  useEffect(() => {
    // Add an event listener to track window width changes
    function handleResize () {
      if (window.innerWidth > 1000) {
        setItemsToShow(4) // For smaller screens, show 1 item
      } else if (window.innerWidth > 768 && window.innerWidth < 1000) {
        setItemsToShow(3) // For smaller screens, show 1 item
      } else if (window.innerWidth > 530 && window.innerWidth < 768) {
        setItemsToShow(2) // For smaller screens, show 1 item
      } else if (window.innerWidth < 530) {
        setItemsToShow(1) // For larger screens, show 4 items (you can adjust this)
      }
    }

    // Initialize the number of items based on the current window width
    handleResize()

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);


  const GetPackages = async () => {
    var token = Hotelapitoken();

    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/latest_packages_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setAllCateogries(response.data.all_cateogries)
      var filter = response.data.latest_packages[0].filter(
        tour =>
          Number(tour.categories) ===
          response.data.all_cateogries[activeItem].id
      )
      setShowTours(filter)
      setAllTours(response.data.latest_packages[0])
    } catch (error) {
      console.error('Error:', error)
    }
  };

  const fetchViewDetail = async (event, id,name) => {
    event.preventDefault()
    var apitoken = Hotelapitoken();
    setLoading(true)
    var data = {
      token: apitoken,
      id: id,
      type: 'tour',
      provider_token: ''
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/get_tour_details',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      Dispatch(ViewTourDetail(response.data))
      const result = name.split(' ').join('-');
      navigation(`/umrah-package/${result}`)
      setLoading(true)
    } catch (error) {
      console.error('Error:', error)
    }
  };



  const Searchhotels = async (name) => {
    setLoading(true);
    var token2=Hotelapitoken();
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);
    var destination_name='';
     var country='';
     var lat='';
     var lon='';
     var pin='';
     if(name==='makkah'){
      destination_name="Makkah";
      country="Saudi Arabia";
      lat=21.478659;
      lon=39.81733639999999;
      pin="SA";
     }else if(name==='madinah'){
      destination_name="Madinah";
      country="Saudi Arabia";
      lat=24.4672132;
      lon=39.6024496;
      pin="SA";
     }else if(name==='jeddah'){
      destination_name="Jeddah";
      country="Saudi Arabia";
      lat=21.5291545;
      lon=39.1610863;
      pin="SA";
     }else if(name==='london'){
     destination_name="London";
     country="United Kingdom";
     lat=51.5072178;
     lon=-0.1275862;
     pin="GB";
    }else if(name==='birmingham'){
     destination_name="Birmingham";
     country="United Kingdom";
     lat=52.48624299999999;
     lon=-1.890401;
     pin="GB";
    }else if(name==='Paris'){
      destination_name="Paris";
      country="France";
      lat=48.856614;
      lon=2.3522219;
      pin="FR";
     }else if(name==='Dubai'){
      destination_name="Dubai";
      country="United Arab Emirates";
      lat=25.2048493;
      lon=55.2707828;
      pin="AE";
     }else if(name==='Los Angeles'){
      destination_name="Los Angeles";
      country="United States";
      lat=34.0549076;
      lon=-118.242643;
      pin="US";
     }else if(name==='Miami'){
      destination_name="Miami";
      country="United States";
      lat=25.7616798;
      lon=-80.1917902;
      pin="US";
     }
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
       "token":token2,
      "currency_slc": 'AFN',
      "currency_slc_iso": 'AF',
      "destination_name":destination_name,
      "country":country,
      "lat":  lat,
      "long": lon,
      "pin": pin,
      "cityd":destination_name,
      "country_code": pin,
      "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
      "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
      "slc_nationality": "",
      "adult": 2,
      "child": 0,
      "room": 1,
      "Adults": [2],
      "children": [0],
      "child_ages1": [],
      "rooms_counter": [1],
      "child_ages2": [],
     
    };
    try {
    
      Dispatch(fetchHotelsSearh(FormData));
      const response = await Axios.post(apiendpoint+'/api/search/hotels/new',FormData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        
        } ,
        
      });
      sessionStorage.removeItem('FlightCheckOut');
      navigation('/hotels');
      Dispatch(fetchHotels(response.data));
      // Handle the API response here
      setLoading(false);
    } catch (error) {
      // Handle errors here
      setLoading(false);
      console.error('Error:', error);
    }
  };


  return (
    <>
      {isloading && <Loading />}
      <div className='container'>
        
        <h3 className='mt-5 text-center'>Explore Saudia Arabia</h3>
        <h3 className='mt-2'>Accomodations</h3>
        <div className="row g-2">
          <div className="col-sm-4">
            <div className="image-top1" onClick={() => Searchhotels("makkah")}>
                <div className="filter-img1"></div>
                <img src={makkah}/>
                <span>Makkah</span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="image-top1" onClick={() => Searchhotels("madinah")}>
                <div className="filter-img1"></div>
                <img src={madinah}/>
                <span>Madinah</span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="image-top1" onClick={() => Searchhotels("jeddah")}>
                <div className="filter-img1"></div>
                <img src={jeddah}/>
                <span>Jeddah</span>
            </div>
          </div>
        
        </div>
        <InternationalRoutes/>
        <h3 className='mt-5 text-center'>Explore the World</h3>
        <h3 className='mt-2'> Accomodations</h3>
        <div className="row g-2">
          <div className="col-sm-6">
            <div className="image-top1 " onClick={() => Searchhotels("london")}>
                <div className="filter-img1"></div>
                <img src={london}/>
                <span>London</span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="image-top1" onClick={() => Searchhotels("birmingham")}>
                <div className="filter-img1"></div>
                <img src={birmingham}/>
                <span>Birmingham</span>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div style={{cursor:'pointer'}} onClick={() => Searchhotels("Paris")} class="destination-blocks bg-white  p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
             <img src={c1} class="img-fluid rounded" alt=""/>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Paris</span>
                  </h4>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div style={{cursor:'pointer'}} onClick={() => Searchhotels("Los Angles")} class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <img src={c3} class="img-fluid rounded" alt=""/>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Los Angles</span>
                  </h4>
                
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div onClick={() => Searchhotels("Dubai")} style={{cursor:'pointer'}} class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
               <img src={c2} class="img-fluid rounded" alt=""/>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Dubai</span>
                  </h4>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div onClick={() => Searchhotels("Miami")} style={{cursor:'pointer'}} class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <img src={c4} class="img-fluid rounded" alt=""/>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Miami</span>
                  </h4>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopularDestinatiosn/>
      <h3 className="mt-5">Trending Packages</h3>
      <h4>Pick a vibe and explore the top Packages</h4>
        <div className='row mt-3'>
          {allcateogries.map((item, index) => (
            <div
              key={item.id}
              className='package-tab-home text-center w-auto col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'
              data-wow-duration="1s" data-wow-delay="0.5s"
            >
              <button
                onClick={() => handleCategorySelect(index)} // Call a function to handle category selection
                className={`tab-package ${
                  activeItem === index ? 'activefilter' : ''
                } btn-block btn-md text-start center-vertically`}
              >
                <img
                  src={url + '/public/uploads/package_imgs/' + item.image}
                  width='50'
                  height='50'
                />{' '}
                <span className='ms-2 me-2'>{t(`${item.title}`)}</span>
              </button>
            </div>
          ))}
         
        </div>
        <hr className=' dashed hr-devider'></hr>
        {showTours.length > 0 && (
          <OwlCarousel
            className='owl-theme custom-owl-carousel'
            items={itemsToShow}
            margin={8}
            nav
          >
            {showTours.map((tour, index) => (
              <div key={index} class='item'>
                <div className=' mt-2'>
                  <div className='card-sl'>
                    <div className='card-image'>
                      <img
                        src={
                          url +
                          '/public/uploads/package_imgs/' +
                          tour.tour_banner_image
                        }
                      />
                    </div>

                    <div className='text-center card-heading'>
                      <h6>{tour.title}</h6>
                    </div>
                    <div className='row'>
                      <div className='d-flex justify-content-between'>
                        <div className='card-text'>
                          <FontAwesomeIcon icon={faPlaneDeparture} />{' '}
                          {moment(tour.start_date).format('DD-MM-YYYY')}
                        </div>
                        <div className='card-text'>
                          <FontAwesomeIcon icon={faPlaneArrival} />{' '}
                          {moment(tour.end_date).format('DD-MM-YYYY')}
                        </div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <div className='card-text'>
                          <FontAwesomeIcon icon={faUser} />{' '}
                          {tour.no_of_pax_days} Seats Available
                        </div>
                        <div className='card-text'>Adults Price</div>
                      </div>

                      <div className='d-flex justify-content-between'>
                        <div className='card-text text-right'>
                          <CIcon className='moon-icon' icon={cilMoon} />{' '}
                          {tour.time_duration} Nights{' '}
                        </div>
                        <div className='card-text text-right'>
                          <b>
                            {tour.currency_symbol}{' '}
                            {tour.quad_grand_total_amount !== null
                              ? tour.quad_grand_total_amount
                              : tour.triple_grand_total_amount !== null
                              ? tour.triple_grand_total_amount
                              : tour.double_grand_total_amount !== null
                              ? tour.double_grand_total_amount
                              : 'N/A'}
                          </b>
                        </div>
                      </div>
                    </div>

                    <a
                      onClick={event => fetchViewDetail(event, tour.id,tour.title)}
                      className='card-button index-package-card'
                    >
                      {' '}
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        )}
        <div className='row'>
          {packagescard.map((item, index) => (
            <div
              key='index'
              className='col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-2'
            >
              <div className='card-sl'>
                <div className='card-image'>
                  <img src={img2} />
                </div>

                <div className='text-center card-heading'>
                  <h6>{item.name}</h6>
                </div>
                <div className='row'>
                  <div className='d-flex justify-content-around'>
                    <div className='card-text'>
                      <FontAwesomeIcon icon={faUser} /> {item.seats} Seats
                      Available{' '}
                    </div>
                    <div className='card-text'>Adults Price</div>
                  </div>

                  <div className='d-flex justify-content-around'>
                    <div className='card-text text-right'>
                      <CIcon className='moon-icon' icon={cilMoon} />{' '}
                      {item.nights} Nights{' '}
                    </div>
                    <div className='card-text text-right'>
                      <b>$ {item.price}</b>
                    </div>
                  </div>
                </div>

                <div className='card-button'> Book Now</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Packages
