import React, { useEffect, useState } from 'react'
import img1 from '../../Images/Carousal/1.jpg'
import '../../Components/Carousal/style.css'
import img2 from '../../Images/Carousal/2.jpg'
import img3 from '../../Images/Carousal/3.jpg'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import img4 from '../../Images/Carousal/4.jpg'
import Carousel from 'react-bootstrap/Carousel'
import SearchBar from '../../Components/SearchBar/Searchbar'
import Packages from '../../Components/Packages/Packages'
import agentpic from '../../Images/Home/agentpic.png'
import adimage from '../../Images/Home/2.jpg'
import Hotels from '../../Components/Hotel/Hotels'
import MorePackages from '../../Components/MorePackages/MorePackages'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loading/Loader'
import Helmet from 'react-helmet'
import wow from 'wowjs'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faEnvelope, faHeadphones, faMoneyBill, faPhone, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
function Home () {
  const [isLoading, setIsLoading] = useState(true)
  const [bottomAdd, setShowbottomAdd] = useState(true)
  const [modalOpen,setModalOpen]=useState(true);
  const navigate=useNavigate();
  useEffect(() => {
    new wow.WOW().init()
    
    const fetchData = async () => {
      
      await new Promise(resolve => setTimeout(resolve, 2000))
      setIsLoading(false)
    }

    fetchData()
  }, []);

  const toggleModal=()=>{
    setModalOpen(!modalOpen);
  };

  const hideadd = () => {
    setShowbottomAdd(false)
  };
  const gotoHajjPage=()=>{
    navigate('/hajj');
  }
  return (
    <>
      <Helmet>
        <title>Sayhan Tourism</title>
      </Helmet>
      {isLoading && <Loader />}
      <Layout>
        <div className='overlay-container'>
          <div className='search-top '>
            <div className='container'>
            <h2>Find your next stay</h2>
            <h3 className='mb-5'>Search Hotels, Flights, Transfers, Tours and much more...</h3>
          
          <SearchBar />
          </div>
          </div>
        </div>
        <Packages />
        {/* <Hotels/> */}
        <MorePackages />
      </Layout>
     
      {/* <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalBody>
          <div className='form-group'>
            <div class="modal-body booking-option-wrapper">
              <div class="modal-close-icon" onClick={toggleModal} tabindex="0" aria-label="close">
              <FontAwesomeIcon icon={faTimes}/>
                </div>
                <div class="expert-wrapper">
                  <div class="expert-image">
                    </div>
                    <div class="expert-availability">Available now</div>
                    </div>
                    <div class="booking-option-details">
                      <div class="booking-option-title" tabindex="0" aria-label="Let Us Help You Book!">Let Us Help You Book!</div>
                      <div class="booking-option-subtitle" tabindex="0" aria-label="[object Object]"><span><b>Speak with a travel expert and get assistance 24/7 </b></span></div>
                      <div class="booking-mobile"><a href="tel:0121 777 2522" title="Phone number" role="button" tabindex="0" aria-label="0121-777-2522">
                       <FontAwesomeIcon icon={faPhone}/> 0121 777 2522</a></div></div></div>
          </div>
          <div class="container content-section mb-0 popup-benefits">
          <h2 class="wHeding">Why to Book with Call Center</h2>
          <div class="row mt-2">
            <div class="col-lg-6 col-sm-6 content-section__main">
              <div class="row">
                <span class="col-3 pr-0">
                  <FontAwesomeIcon size='2x' className='st1' icon={faHeadphones}/>
                  </span>
                  <span class="col-9 pt-0"><h3 class="mb-2 font-weight-normal">Expert guidance by our <b class="d-block">Travel experts</b></h3></span></div></div>
                  <div class="col-lg-6 col-sm-6 content-section__main"><div class="row"><span class="col-3 pr-0">
                  <FontAwesomeIcon size='2x' className='st1' icon={faEnvelope}/>

                    </span>
                    <span class="col-9 pt-0"><h3 class="mb-2 font-weight-normal">Immediate <b class="d-block">booking confirmation</b></h3></span></div></div>
                    <div class="col-lg-6 col-sm-6 content-section__main"><div class="row"><span class="col-3 pr-0">
                    <FontAwesomeIcon size='2x' className='st1' icon={faClock}/>
                      </span><span class="col-9 pt-0"><h3 class="mb-2 font-weight-normal"><b class="d-block">24-hour </b>cancellation</h3></span></div></div>
                      <div class="col-lg-6 col-sm-6 content-section__main">
                        <div class="row"><span class="col-3 pr-0">
                        <FontAwesomeIcon size='2x' className='st1' icon={faMoneyBill}/>
                          </span><span class="col-9 pt-0"><h3 class="mb-2 font-weight-normal"><b class="d-block">Flexible payment</b> plans</h3></span></div></div></div>
          </div>
        </ModalBody>
      </Modal> */}
    
    </>
  )
}

export default Home
