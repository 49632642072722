import React,{useState,useEffect} from "react";
import Layout from "../../Components/Layout/Layout";
import { useSelector } from "react-redux";
import { RangeSlider } from 'rsuite';
import TransferCard from "../../Components/Transfer/TransferCard";
function TransferListing(){
  const [value, setvalue] = useState({min:100,max:1000});
const [minValue, setMinValue] = useState(value.min);
const [maxValue, setMaxValue] = useState(value.max);
const [rangeValue, setRangeValue] = useState([value.min, value.max]);
  const TransferDetail=useSelector((state) => state.hotels.TrSeResponse);

useEffect(()=>{
  setvalues();
},[]);

  const setvalues=()=>{
    var minValue1=0;
    var maxValue1 = 0;
    var amounts;
    if(TransferDetail?.transfers_list !== 0){
      amounts = TransferDetail?.transfers_list.map(item => item.total_fare_markup);
      if(amounts.length !==0){
        minValue1 = Math.min(...amounts);
         maxValue1 = Math.max(...amounts);
      };
    };
    setMinValue(minValue1);
      setMaxValue(maxValue1);
     value.max=maxValue1;
     value.min=minValue1;
      
  };

  const handleChange = (newRangeValue) => {
    setRangeValue(newRangeValue);
    setMinValue(newRangeValue[0]);
    setMaxValue(newRangeValue[1]);
  };
  console.log(TransferDetail);
    return(
        <>
        <Layout>
             <div className='container'>
                    <div className='row mt-3 hotel-top'>
                        <div className='col-md-6 '>
                            <h3 className='title font-size-24 tc' id='tours_result'>
                           {TransferDetail?.transfers_list.length} Result found
                            </h3>
                        </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-md-3 col-md-pull-9'>
                    <div className='page-sidebar hide-page_filter'>
              <div className='widget widget_price_filter'>
                <div className='mb-0'>
                  <label className='form-label'>Price Level</label>
                  <RangeSlider
                      value={rangeValue}
                      onChange={handleChange}
                      min={value.min}
                      tooltip={false}
                      max={value.max}
                      step={1}
                    />
                       <div className='pt-5'>
                    <div className='fw-bold mb-2'>
                      Min: <span id='kt_slider_basic_min'>{minValue.toFixed(0)}</span>
                    </div>
                    <div className='fw-bold mb-2'>
                      Max: <span id='kt_slider_basic_max'>{maxValue.toFixed(0)}</span>
                    </div>
                  </div>
                  <button  className='btn select-styling search-btn1 mb-1'>Filter</button>
                </div>
              </div>
              {/* <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' >                
                <h3>Star Rating</h3>                
                <FontAwesomeIcon icon={faAngleDown}/>
                </div>
               <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button  className='btn select-styling search-btn1'>Filter</button>
                </div>
                
              </div> */}
            </div>
                    </div>
                    <div className='col-md-9 col-md-push-3'>
                        <TransferCard  TransferData={TransferDetail?.transfers_list}/>
                    </div>
                </div>
             </div>
        </Layout>

        </>
    );
}

export default TransferListing;