import React,{useState,useEffect} from "react";
import Autocomplete from 'react-google-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobe,
  faCalendar
} from '@fortawesome/free-solid-svg-icons';
import { TransferApiToken,ApiEndPoint } from "../GlobalData/GlobalData";
import DatePicker from 'react-datepicker';
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loader";
import { TransferSearchResponse } from "../../Redux/Actions/actions";
import Axios from "axios";
function TransferSearch(){
  const endpoint=ApiEndPoint();
  const tokenApi=TransferApiToken();
  const dispatch=useDispatch();
  const navigate=useNavigate();
    const [pickUp, setPickUp] = useState([]);
    const [dropOf, setDropOf] = useState([]);
    const [initialRender, setInitialRender] = useState(true);
    const [transferDate, setTransferDate] = useState('');
    const [tripType, setTripType] = useState('One-Way');
    const [Passenger, setPassenger] = useState('');
    const [Vehicles, setVehicles] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [placeDetail, setPlaceDetail] = useState({
        city: '',
        country: '',
        countrycode: '',
        lat: 0,
        long: 0,
        checkindate: '',
        Checkoutdate: ''
      });
    const handleTransferDateChange = date => {
        setTransferDate(date)
      };

      const handleSelectTripType=event=>{
        setTripType(event.target.value);
      };

      const handlePassenger=(event)=>{
        setPassenger( event.target.value);
        
      };
      const handleVehicles=(event)=>{
        setVehicles( event.target.value);
      };


  

      // useEffect(() => {
      //   const loadScript = () => {
      //     const script = document.createElement('script');
      //     script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&callback=initAutocomplete&language=en&output=json&key=AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY`;
      //     script.async = true;
      //     script.defer = true;
      //     document.head.appendChild(script);
      //   };
    
      //   const initAutocomplete = () => {
      //     const input = document.getElementById('location-input');
      //     const autocomplete = new window.google.maps.places.Autocomplete(input);
    
      //     // Add any additional autocomplete options or event listeners here
      //     // Add event listener for the 'place_changed' event
      //     autocomplete.addListener('place_changed', () => {
      //       const selectedPlace = autocomplete.getPlace();
    
      //       // Log the selected location object
      //       console.log('Selected Location:', selectedPlace);
      //     });
    
      //     // console.log(autocomplete);
      //   };

      //   // Load Google Maps API script
      //   loadScript();
    
      //   // Expose the initAutocomplete function to the global scope
      //   window.initAutocomplete = initAutocomplete;
       
      //   // Cleanup function to remove the script when the component unmounts
      //   return () => {
      //     const script = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');
      //     if (script) {
      //       script.remove();
      //     }
      //   };
      // }, []);
     
      const SearchTransfer = async () => {
      
        var pickup='';
        var dropof='';
        if (pickUp.length !== 0) {
          if ( pickUp.address_components.filter( f =>
                JSON.stringify(f.types) ===
                JSON.stringify(['locality', 'political'])
            )[0]
          ) {
            const city1 = pickUp.address_components.filter(
              f =>
                JSON.stringify(f.types) ===
                JSON.stringify(['locality', 'political'])
            )[0]?.short_name
           
            pickup=city1;
          }
        };
        if (dropOf.length !== 0) {
          if ( dropOf.address_components.filter( f =>
                JSON.stringify(f.types) ===
                JSON.stringify(['locality', 'political'])
            )[0]
          ) {
            const city1 = dropOf.address_components.filter(
              f =>
                JSON.stringify(f.types) ===
                JSON.stringify(['locality', 'political'])
            )[0]?.short_name
           
            dropof=city1;
          }
        };

        if(transferDate==='')
        {
            toast.info('Please Select Transfer Date.', {
              position: toast.POSITION.TOP_RIGHT
          });
          return;
        };
        if(Passenger==='')
        {
            toast.info('Please Enter Passenger.', {
              position: toast.POSITION.TOP_RIGHT
          });
          return;
        };
        setIsLoading(true);
        var data={
          'token':tokenApi,
          'name_pickup_location_plc':pickup,
          'name_drop_off_location_plc':dropof,
          'pick_up_date':moment(transferDate).format('YYYY-MM-DD'),
          'trip_type':tripType,
          'passenger':Number(Passenger),
          'no_of_vehicles':Number(Vehicles),
        };
       
         try {
         
           const response = await Axios.post(endpoint+'/api/transfers_search_react',data, {
             headers: {
               "Access-Control-Allow-Origin": "*",
               // Required for CORS support to work
                "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
               "Access-Control-Allow-Headers":
               "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
               "Access-Control-Allow-Methods": "POST, OPTIONS"
             } ,
             
           });

           console.log(response);
           setIsLoading(false);
           if(response.data.message==='Success')
           {
            dispatch(TransferSearchResponse(response.data));
            navigate('/transfer-search');
          
           }else{
                  toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
               
           }
         
         } catch (error) {
           // Handle errors here
           setIsLoading(false);
           console.error('Error:', error);
         }
       };
    return (
        <>
        <ToastContainer/>
        {isLoading && (
          <Loading/>
        )}
        <div className='tab-content'>
                <div className='tab-pane fade show active'>
                    <div className='row check-availabilty'>
                        <div className='row'>
                        <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black'
                           >
                            PICK-UP LOCATION
                           </label>
                           <div className='field-icon-wrap'>
                             <span className='form-control-feedback'>
                               <FontAwesomeIcon icon={faGlobe} />{' '}
                             </span>
                             <Autocomplete
                               className='form-control search-form-control text-start select-styling '
                               placeholder='Enter Location'
                               apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
                               onPlaceSelected={place => {
                                setPickUp(place);
                                 
                               }}
                               options={{
                                 types: ['(regions)'],
                                 componentRestrictions: null
                               }}
                             />

                              {/* <input type="text" 
                                  id="location-input"
                                  name="pick-up"
                                    className='form-control search-form-control text-start select-styling '
                                    placeholder='Pick-up Location'
                                  /> */}

                           </div>
                         </div>
                         <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black'
                           >
                             DROP-OFF LOCATION
                           </label>
                           <div className='field-icon-wrap'>
                             <span className='form-control-feedback'>
                               <FontAwesomeIcon icon={faGlobe} />{' '}
                             </span>
                             <Autocomplete
                               className='form-control search-form-control text-start select-styling '
                               placeholder='Enter Location'
                               apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
                               onPlaceSelected={place => {
                                setDropOf(place);
                               }}
                               options={{
                                 types: ['(regions)'],
                                 componentRestrictions: null
                               }}
                             />
                           </div>
                         </div>
                         <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black'
                           >
                            PICK-UP DATE
                           </label>
                           <div className='field-icon-wrap'>
                             <span className='form-control-feedback'>
                               <FontAwesomeIcon icon={faCalendar} />{' '}
                             </span>
                             <DatePicker
                                selected={transferDate}
                                onChange={handleTransferDateChange}
                                placeholderText='Select Date'
                                dateFormat='dd/MM/yyyy' // Customize date format as needed
                                className='form-control text-start select-styling ps-5'
                            />
                           </div>
                         </div>
                         <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black'
                           >
                            TRIP TYPE
                           </label>
                           <div className='field-icon-wrap'>
                             <select

                                value={tripType}
                                onChange={handleSelectTripType}
                                className='form-select select-styling'
                                aria-label='Default select example'
                            >
                                <option selected value='One-Way'>One-Way</option>
                                <option value='Return'>Return</option>
                                <option value='All_Round'>All_Round</option>

                            </select>
                           </div>
                         </div>
                         <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black'
                           >
                           PASSENGER
                           </label>
                           <div className='field-icon-wrap'>
                            <input type="number" onChange={handlePassenger} value={Passenger} placeholder="Passenger" className="form-control select-styling"/>
                           </div>
                         </div>
                         <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                           <label
                             htmlFor='checkin_date'
                             className='font-weight-bold text-black'
                           >
                          Vehicles
                           </label>
                           <div className='field-icon-wrap'>
                            <input type="number" onChange={handleVehicles} value={Vehicles} placeholder="Vehicles" className="form-control select-styling"/>
                           </div>
                         </div>
                         <div className='col-md-6 col-lg-3 text-center align-self-end'>
                
                                <button onClick={SearchTransfer}  className='btn btn-primary btn-block select-styling search-btn1'>
                                    Search
                                </button>
                       
                    </div>
                        </div>
                    </div>
                </div> 
        </div>   
        </>
    );
}

export default TransferSearch;